<template>
  <div class="md:w-1/2 mx-auto p-10 tnc">
    <h4
      style="text-align: center"
      class="font-extrabold underline underline-offset-8"
    >
      SYARAT DAN KETENTUAN
    </h4>
    <p class="text-justify">&nbsp;</p>
    <div class="mb-8">
      <p class="text-justify"><strong>Syarat dan Ketentuan Harapan Jaya Prima</strong></p>
      <p class="text-justify">
        PT. Harapan Jaya Prima merupakan layanan transportasi angkutan penumpang
        umum yang mengedepankan keselamatan dan kenyamanan penumpang . Maka dari
        itu Syarat dan Ketentuan Harapan Jaya Prima sebagaimana telah diatur
        dibawah ini berlaku, disepakati, dan dianggap telah diterima dan
        disetujui oleh Pengguna selaku penumpang pada saat pengguna menggunakan
        jasa HJP &nbsp;untuk proses mobilisasi dari satu tempat ke tempat lain
        sesuai kebutuhan pengguna .
      </p>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Pendahuluan </strong></p>
      <div class="pl-4">
        <div class="flex">
          <div class="mr-4">&#10004;</div>
          <p class="text-justify">
            Harapan Jaya Prima (HJP) adalah perseroan yang didirikan berdasarkan
            hukum Negara Republik Indonesia
          </p>
        </div>
        <div class="flex">
          <div class="mr-4">&#10004;</div>
          <p class="text-justify">
            HJP adalah perusahaan transportasi angkutan penumpang umum dengan
            izin penyelenggaraan dari Dinas Perhubungan Provinsi Jawa Timur
            nomor : 06.188.4/0039/113.4.1/2020 dan Kementrian Perhubungan RI
            Direktorat Jendral Perhubungan Darat&nbsp; nomor :
            SK.00013/AJ.205/4/DJDD/2018
          </p>
        </div>
      </div>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Isi</strong></p>
      <div class="pl-4">
        <div class="flex">
          <div class="mr-4">&#10004;</div>
          <p class="text-justify">
            Angkutan Umum adalah sarana kendaraan atau moda angkutan yang
            digunakan untuk mengangkut orang atau barang dari suatu tempat ke
            tempat yang lain dengan dipungut bayaran
          </p>
        </div>
        <div class="flex">
          <div class="mr-4">&#10004;</div>
          <p class="text-justify">
            Mitra Kru jalan ( Pengemudi, Kondektur dan Pembantu pengemudi )
            adalah Mitra HJP yang melakukan tugas untuk melayani penumpang
          </p>
        </div>
        <div class="flex">
          <div class="mr-4">&#10004;</div>
          <p class="text-justify">
            Bus Non Ekonomi / Patas adalah pelayanan yang dilengkapi fasilitas
            tambahan antara lain pengatur suhu udara ( AC ), pengatur tempat
            duduk ( reclining seat ) dan toilet
          </p>
        </div>
        <div class="flex">
          <div class="mr-4">&#10004;</div>
          <p class="text-justify">
            Bus Ekonomi / ATB adalah pelayanan dengan fasilitas standar dan
            dengan harga yang ekonomis
          </p>
        </div>
        <div class="flex">
          <div class="mr-4">&#10004;</div>
          <p class="text-justify">
            Akun HJP adalah media atau platform pencatatan transaksi antara
            kondektur dan penumpang serta kontrol dan kondektur
          </p>
        </div>
        <div class="flex">
          <div class="mr-4">&#10004;</div>
          <p class="text-justify">
            Pemilik Akun adalah pengguna aplikasi yang terdaftar pada sistem
            aplikasi HJP
          </p>
        </div>
        <div class="flex">
          <div class="mr-4">&#10004;</div>
          <p class="text-justify">Penumpang adalah pengguna angkutan umum</p>
        </div>
        <div class="flex">
          <div class="mr-4">&#10004;</div>
          <p class="text-justify">
            Tarif adalah biaya yang dibayarkan penumpang atas layanan yang
            digunakan oleh pengguna
          </p>
        </div>
      </div>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Ketentuan penumpang</strong></p>
      <div class="pl-8">
        <ol>
          <li class="text-justify">Penumpang wajib berkarcis</li>
          <li class="text-justify">Barang rusak atau hilang resiko penumpang</li>
          <li class="text-justify">
            Sudah termasuk iuran wajib penumpang sesuai UU No.33 Tahun 1964
          </li>
          <li class="text-justify">Free pass tidak berlaku</li>
          <li class="text-justify">
            Dalam pelayanan penumpang HJP tidak menjamin bahwa seluruh
            perjalanan berlangsung lancar dan nyaman yang disebabkan oleh
            peristiwa yang mungkin timbul diluar kemampuan HJP diwilayah yang
            dilalui transportasi HJP
          </li>
        </ol>
      </div>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Jenis pelayanan</strong></p>
      <p class="text-justify">Jenis pelayanan HJP dibagi atas 3 :</p>
      <div class="pl-8">
        <ol>
          <li class="text-justify">AKAP &#8594; Antar Kota Antar Provinsi</li>
          <li class="text-justify">AKDP &#8594; Antar Kota Dalam Provinsi</li>
          <li class="text-justify">Pariwisata</li>
        </ol>
      </div>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Kategori Penumpang</strong></p>
      <div class="pl-8">
        <ul>
          <li class="text-justify">Penumpang Umum</li>
          <li class="text-justify">Penumpang Berseragam</li>
          <li class="text-justify">Penumpang Rombongan</li>
        </ul>
      </div>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Info </strong></p>
      <div class="pl-8">
        <ul>
          <li class="text-justify">Pembayaran karcis dilakukan 2 cara yaitu Tunai dan Non Tunai</li>
          <li class="text-justify">
            Apabila ada kendala ketika melakukan pembayaran non tunai maka
            transaksi gagal. Dan penumpang dapat mengulangi transaksi lagi
            dengan non tunai / tunai
          </li>
          <li class="text-justify">
            Apabila bus mengalami kendala dan tidak bisa melanjutkan perjalanan
            maka akan pengembalian sisa uang perjalanan secara tunai
          </li>
        </ul>
      </div>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Tarif</strong></p>
      <p class="text-justify">
        HJP telah menetapkan tarif atas layanan sesuai dengan yang tertera pada
        platform HJP.
      </p>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Jam dan waktu operasional agen :</strong></p>
      <div class="pl-4">
        <div class="flex">
          <div class="mr-4">&#10004;</div>
          <p class="text-justify">
            Seluruh armada HJP beroperasional setiap hari tanpa libur ( termasuk
            akhir pekan dan hari libur nasional ) 24 jam setiap harinya
          </p>
        </div>
      </div>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Jaminan Kepemilikan Akun HJP</strong></p>
      <div class="pl-4">
        <div class="flex">
          <div class="mr-4">&#10004;</div>
          <p class="text-justify">
            Pemilik Akun menjamin bahwa dalam hal penggunaan akun adalah benar
            dan tidak melanggar norma serta hukum perundang-undangan yang
            berlaku
          </p>
        </div>
        <div class="flex">
          <div class="mr-4">&#10004;</div>
          <p class="text-justify">
            Pemilik Akun menjamin serta membebaskan HJP dari segala tuntutan
            atas penyalahgunaan Akun HJP
          </p>
        </div>
        <div class="flex">
          <div class="mr-4">&#10004;</div>
          <p class="text-justify">
            HJP berhak melakukan tindakan yang dianggap perlu secara langsung
            (menonaktifkan akun) tanpa persetujuan dari Pemilik Akun dalam hal
            terdapat penyalahgunaan Akun HJP termasuk pelayanan fiktif.
          </p>
        </div>
      </div>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Hak Cipta</strong></p>
      <p class="text-justify">
        Seluruh desain, logo, merek, foto dan gambar yang berkaitan dengan
        Layanan HJP baik secara digital maupun dicetak tidak dapat digunakan dan
        diakui oleh pihak manapun dan untuk kepentingan apapun, kecuali ada
        persetujuan tertulis dari HJP sebagai penyedia layanan.
      </p>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Penolakan </strong></p>
      <p class="text-justify">
        Sejauh diizinkan oleh hukum yang berlaku, kami mengecualikan semua
        representasi, jaminan, dan ketentuan yang berkaitan dengan situs web
        kami dan penggunaan situs web ini. Tidak ada dalam penolakan ini yang
        akan:
      </p>
      <div class="pl-8">
        <ul>
          <li class="text-justify">
            membatasi atau mengecualikan tanggung jawab kami atau Anda atas
            kematian atau cedera pribadi;
          </li>
          <li class="text-justify">
            membatasi atau mengecualikan tanggung jawab kami atau Anda untuk
            penipuan atau penggambaran yang salah;
          </li>
          <li class="text-justify">
            membatasi salah satu dari kewajiban kami atau Anda dengan cara apa
            pun yang tidak diizinkan berdasarkan hukum&nbsp;
          </li>
          <li class="text-justify">
            mengecualikan salah satu dari kewajiban kami atau Anda yang mungkin
            tidak timbul dalam kontrak, dalam gugatan hukum dan untuk
            pelanggaran kewajiban hukum.
          </li>
        </ul>
      </div>
      <p class="text-justify">
        Selama situs web dan informasi serta layanan di situs web disediakan
        secara gratis, kami tidak akan bertanggung jawab atas kehilangan atau
        kerusakan apa pun.
      </p>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Keluhan atau Pertanyaan</strong></p>
      <p class="text-justify">
        Apabila para penumpang memiliki pertanyaan atau keluhan terhadap
        pelayanan transportasi &nbsp;melalui HJP, Pengirim dapat melihat FAQ
        atau DM Instagram HJP (@ptharapanjayaprima), atau melalui Whatsapp CS
        HJP 0815 1598 8889
      </p>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Ketentuan Lain-Lain</strong></p>
      <div class="pl-4">
        <div class="flex">
          <div class="mr-4">&#10004;</div>
          <p class="text-justify">
            Selain perjanjian atau syarat dan ketentuan yang tertulis pada
            karcis HJP tidak dapat dituntut dan dibebani dengan perjanjian atau
            dasar hukum lainnya kecuali dengan perjanjian tertulis yang
            disetujui oleh penanggungjawab HJP.
          </p>
        </div>
      </div>
      <div class="pl-4">
        <div class="flex">
          <div class="mr-4">&#10004;</div>
          <p class="text-justify">
            Apabila salah satu ketentuan dalam S&K ini menjadi tidak sah atau
            tidak dapat diberlakukan secara sah maka ketentuan-ketentuan lainnya
            tidak akan terpengaruh
          </p>
        </div>
      </div>
      <div class="pl-4">
        <div class="flex">
          <div class="mr-4">&#10004;</div>
          <p class="text-justify">
            HJP dapat mengubah dan merevisi S&K sewaktu-waktu sesuai dengan
            kebutuhan bisnis yang diperlukan selama penyediaan layanan HJP
          </p>
        </div>
      </div>
      <div class="pl-4">
        <div class="flex">
          <div class="mr-4">&#10004;</div>
          <p class="text-justify">
            PT.Harapan Jaya Prima berhak untuk memantau semua komentar dan
            menghapus komentar yang dapat dianggap tidak pantas, menyinggung,
            berbau sara atau menyebabkan pelanggaran terhadap Syarat dan
            Ketentuan ini.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
